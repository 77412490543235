<template>
    <div class="dashboard__wall">
<!--        <div class="dashboard__wall-panel">-->
<!--            <div class="dashboard__wall-panel__row">-->
<!--                <div class="dashboard__wall-panel__col">-->
<!--                    <button-->
<!--                        class="dashboard__wall-panel__trigger"-->
<!--                        type="button"-->
<!--                        title="Настройки ленты"-->
<!--                        aria-label="Feed settings"-->
<!--                        @click="onWallSettings"-->
<!--                    >-->
<!--                        <svg class="staff-month__icon" width="15" height="15" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">-->
<!--                            <path d="M19.4002 12.1916L17.5619 10.7541C17.6189 10.2524 17.6189 9.74585 17.5619 9.24413L19.4019 7.80582C19.7467 7.52813 19.8388 7.04117 19.6194 6.65664L17.7069 3.35084C17.4866 2.96389 17.0175 2.7949 16.6011 2.95252L14.4344 3.82419C14.0272 3.52881 13.5911 3.27556 13.1327 3.06838L12.8002 0.766707C12.7403 0.324833 12.3612 -0.00360296 11.9152 2.98427e-05H8.08191C7.63969 -0.0026264 7.26364 0.322138 7.20192 0.760027L6.8686 3.06834C6.41161 3.27752 5.97618 3.53095 5.5686 3.82501L3.39607 2.95166C2.98397 2.7892 2.51526 2.95615 2.29858 3.34248L0.384407 6.65414C0.159057 7.0393 0.25183 7.5316 0.601906 7.80832L2.44022 9.24581C2.38268 9.74749 2.38268 10.2541 2.44022 10.7558L0.600227 12.1933C0.254838 12.4706 0.16226 12.9578 0.381907 13.3425L2.29358 16.6491C2.51358 17.0364 2.98299 17.2055 3.3994 17.0475L5.56607 16.1758C5.97356 16.4713 6.40997 16.7248 6.86856 16.9325L7.20188 19.2325C7.26055 19.6732 7.63731 20.0017 8.08188 20H11.9152C12.3582 20.0035 12.7355 19.6786 12.7977 19.24L13.131 16.9316C13.5879 16.7224 14.0233 16.4689 14.431 16.175L16.606 17.0491C17.0181 17.2112 17.4866 17.0444 17.7035 16.6583L19.6235 13.3333C19.8384 12.9494 19.7439 12.4663 19.4002 12.1916ZM9.99855 14.1666C7.69739 14.1666 5.83188 12.3012 5.83188 9.99995C5.83188 7.69875 7.69735 5.83329 9.99855 5.83329C12.2997 5.83329 14.1652 7.69875 14.1652 9.99995C14.1624 12.3 12.2986 14.1639 9.99855 14.1666Z" />-->
<!--                        </svg>-->
<!--                    </button>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

        <new-post
            :after-create="afterCreate"
        />

        <div class="dashboard__block wall__more hidden">
            <a href="javascript:void(0)" class="wall__more-btn" v-show="count_more > 0"  @click="loadNewPost">
                Показать ещё <span class="wall__more-count">{{count_more}}</span>
            </a>
        </div>

        <div class="wall__wrapper">

            <div v-if="isPostsEmpty" class="wall__empty dashboard__block">
                <div class="wall__empty-icon"/>
                <span class="wall__empty-title">На стене пока нет записей</span>
            </div>
            <wall-item
                v-for="(item, index) of postsList"
                :index="index"
                :item="item"
                :key="item.id ? `wall_${item.id}` : `wall_${item.created_at}`"
                :on-like="onLike"
                :on-delete="deletePost"
            />

            <loading-spinner
                v-if="isPostsLoading"
                class="wall__spinner"
                height="8px"
                width="8px"
            />
            <mugen-scroll
                :handler="infiniteScrollHandler"
                :should-handle="!isPostsLoading"
            />
        </div>
    </div>
</template>

<script>
    import session from '@/api/session';
    // import VueNativeSock from 'vue-native-websocket';
    import { mapState } from 'vuex';

    // Vue.use(VueNativeSock, window.wsRoot, { connectManually: true, });
    export default {
        name: 'Wall',
        components: {
            Material : () => import('./Post/Material'),
            NewPost : () => import('./Post/NewPost'),
            MugenScroll : () => import('vue-mugen-scroll'),
            LoadingSpinner : () => import('@/components/LoadingSpinner'),
            WallItem : () => import('./WallItem')
        },
        data() {
            return {
                count_more: 0,
                postsListSort: [],
                postsList: [],
                isPostsLoading: false,
                isPostsEmpty: false,
                nextPosts: '/api/v1/wall/'
            };
        },
        created() {
            // this.createSignalWebSocket();
            this.nextPosts = `/api/v1/wall/?post=${this.wallSettings.userPost.value}&advert=${this.wallSettings.advertPost.value}&user=${this.wallSettings.newUsers.value}&material=${this.wallSettings.newMaterials.value}`;
        },
        computed: {
            ...mapState('default_data', [
                'current_user',
                'wallSettings'
            ]),

            postsSort() {
                let posts = [];
                let sortPostsCollections = {};

                this.postsList.forEach(item => {
                    if (item.view === 'material') {
                        this.postsList.splice(this.postsList.indexOf(item), 1);
                        const collection = sortPostsCollections[item.created_at.split(' ')[0]];
                        sortPostsCollections[item.created_at.split(' ')[0]] = collection && collection.length ? [...collection, item] : [item];
                    } else if (this.postsList.indexOf(item) === -1 && posts.indexOf(item) === -1) {
                        posts.push(item)
                    }
                });

                Object.keys(sortPostsCollections).forEach(item => {
                    let obj = {
                        view: 'material',
                        created_at: item + ' 23:59:59',
                        list: []
                    };

                    if (this.postsList.indexOf(obj) === -1 && posts.indexOf(obj) === -1) {
                        posts.push(obj);
                        console.log('created_at', obj.created_at);
                    }
                });

                posts = posts.sort(function(a, b) {
                    return new Date(b.created_at) - new Date(a.created_at);
                });

                this.postsList.push(...posts);
            }
        },
        methods: {
            createSignalWebSocket() {
                /*session
                    .get('/api/v1/global_setting/domain-info/')
                    .then(response => {
                        let data = response.data;
                        this.subdomain = data['subdomain'];
                        this.$connect(`${window.wsRoot}dashboard/wall/${this.subdomain}/`, {
                            format: 'json',
                            reconnection: true, // (Boolean) whether to reconnect automatically (false)
                            reconnectionAttempts: 5, // (Number) number of reconnection attempts before giving up (Infinity),
                            reconnectionDelay: 3000, // (Number) how long to initially wait before attempting a new (1000)
                        });
                        this.$socket.onmessage = (event) => {
                            let message = JSON.parse(event.data);
                            if (message.hasOwnProperty('post')) {
                                let url = `${window.wsRoot}dashboard/wall/${this.subdomain}/${this.current_user.id}/`;

                                let wallSocket = new WebSocket(url);
                                wallSocket.onopen = () => {
                                    let date = null;
                                    if (this.postsList.length > 0) {
                                        date = this.postsList[0].system_date
                                    }
                                    wallSocket.send(JSON.stringify({
                                        'type': 'count',
                                        'data': {
                                            'user_id': this.current_user.id,
                                            'subdomain': this.subdomain,
                                            'date': date
                                        }
                                    }));
                                    wallSocket.onmessage = (event) => {
                                        let message = JSON.parse(event.data);
                                        this.count_more = message['count'];
                                    };
                                    // Обработка ошибок.
                                    wallSocket.onerror = function (error) {
                                        console.log('WebSocket Error: ' + error);
                                    };
                                };
                                // wallSocket.disconnect()
                            }
                        };
                    })
                    .catch(() => {
                    })*/
            },
            async sendSignalWebSocket() {
                /*this.$socket.send(JSON.stringify({
                    'data': {},
                    'type': 'notify'
                }));*/
            },
            async onLike(id) {
                try {
                    await session.post(`/api/v1/wall/${id}/like/`);

                    let request = session.get(`/api/v1/wall/${id}/`);
                    let likedPost = await request;

                    /* Обновить количество и статус лайков поста после лайка */
                    let postInData = this.postsList.find(post => post.id === id);
                    postInData.is_like = likedPost.data.is_like;
                    postInData.likes.count = likedPost.data.likes.count;
                } catch (error) {
                    this.errorHandler(error);
                }

            },
            loadNewPost() {
                if (this.postsList.length > 0) {
                    let date = null;
                    date = this.postsList[0].system_date;
                    session
                        .post('/api/v1/wall/new-post/', {
                            date: date
                        })
                        .then(response => {
                            this.count_more = 0;
                            let posts = response.data;
                            posts.push(...this.postsList);
                            this.postsList = posts;
                        })
                        .catch(() => {
                        })
                }
            },
            async getPosts() {
                try {
                    let request = session.get(this.nextPosts);
                    let response = await request;

                    let posts = response.data.results;

                    // posts = posts.map(this.addTimeKey);

                    if (response.data.next) {
                        this.nextPosts = `${response.data.next}&post=${this.wallSettings.userPost.value}&advert=${this.wallSettings.advertPost.value}&user=${this.wallSettings.newUsers.value}&material=${this.wallSettings.newMaterials.value}`;
                    } else {
                        this.nextPosts = null;
                    }

                    return posts;

                } catch (error) {
                    this.errorHandler(error);
                }
            },
            async afterCreate() {
                try {
                    this.nextPosts = `/api/v1/wall/?post=${this.wallSettings.userPost.value}&advert=${this.wallSettings.advertPost.value}&user=${this.wallSettings.newUsers.value}&material=${this.wallSettings.newMaterials.value}`;
                    this.postsList = await this.getPosts();
                    await this.infiniteScrollHandler();
                } catch (error) {
                    this.errorHandler(error);
                }
            },
            async deletePost(id) {
                try {
                    this.$swal({
                        customClass: {
                            confirmButton: 'btn btn-lg btn-alt-success m-5',
                            cancelButton: 'btn btn-lg btn-alt-danger m-5'
                        },
                        title: 'Удалить пост?',
                        text: "Данную операцию будет невозможно отменить",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Удалить пост',
                        cancelButtonText: 'Отменить'
                    }).then(async (result) => {
                        if (result.value) {
                            await session.delete(`/api/v1/wall/${id}/`);
                            this.postsList = this.postsList.filter(post => post.id !== id);
                        }
                    });
                } catch (error) {
                    this.errorHandler();
                }
            },
            async infiniteScrollHandler() {
                // Метод для подгрузки постов при скроллинге
                this.isPostsLoading = true;
                try {
                    if (!this.nextPosts) {
                        this.isPostsLoading = false;
                        return false;
                    }

                    let posts = await this.getPosts();

                    this.postsList.push(...posts);

                    /*
                        при помощи setTimeout реализуем исскуственную задержку,
                        т.к. из-за кнопки "PageDown", сервер не может обрабатывать запросы с большой скоростью.
                     */
                    setTimeout(() => this.isPostsLoading = false, 1500)

                } catch (error) {
                    this.errorHandler(error)
                    this.isPostsLoading = false;
                }
            },
            /* Нужно для того, чтобы передать объекту уникальный key для корректной отрисовки
                * иначе посты будут кэшироваться и не будут обновляться данные */
            addTimeKey(post) {
                return {
                    ...post,
                    timeKey: new Date().getTime() - new Date(post.created_at).getTime()
                };
            },
            errorHandler(error) {
                this.$swal({
                    title: 'Ошибка!',
                    text: 'При обработке запроса произошла ошибка на сервере',
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1400,
                });
                this.isPostsLoading = false;
            },

            onWallSettings() {
                const ModalWellSettings = () => import('./ModalWallSettings.vue');
                this.$modal.show(ModalWellSettings, {
                    afterUpdate: this.onWallUpdate
                }, {
                    name: 'ModalWallSettings',
                    adaptive: true,
                    maxWidth: 600,
                    width: '100%',
                    height: 'auto'
                });
            },

            async onWallUpdate() {
                await this.afterCreate();
            }
        }
    }
</script>

<style lang="sass">
    @import "#sass/v-style"

    .wall
        &__spinner
            margin-top: 25px

        &__item
            margin-bottom: 25px
            .comments
                border-top: 1px solid $border-gray
                padding: 30px
        &__advert
            border: 4px solid $gold
            box-shadow: none !important
        &__empty
            padding: 30px
            width: 100%
            display: flex
            flex-direction: column
            align-items: center
            justify-content: center
        &__more
            display: flex
            align-items: center
            justify-content: center
            border-bottom: 1px solid rgba(0,0,0,.03)
            &-btn
                color: $link-main
                padding: 15px 0
                width: 100%
                text-align: center
                &:hover
                    background: #f5f7fa
            &-count
                font-weight: 600

            &-title
                margin-top: 25px
                font-size: 1rem
                color: #76787a
                text-align: center

            &-icon
                height: 75px
                width: 75px
                background: url('../../assets/img/favicons/no_posts.png') no-repeat
                background-size: contain
        &__material
            padding: 30px
            @media (max-width: 767px)
                padding: 30px 15px
            &-title
                margin-bottom: 28px
            &-item
                display: flex
                margin-bottom: 20px
                font-size: 16px
                line-height: 24px
                @media (max-width: 767px)
                    margin-bottom: 14px
                    font-size: 14px
                    line-height: 22px
                &:not(:first-of-type)
                    padding-top: 20px
                    border-top: 1px solid #DBDBDB
                    @media (max-width: 767px)
                        padding-top: 14px
            &-date
                flex-shrink: 0
                margin-right: 15px
                font-weight: 600
                color: #A4AEBD
                @media (max-width: 767px)
                    margin-right: 7px
            &-post a
                color: #2173D3
            &-more
                display: inline-block
                padding: 0
                margin: 0
                font-size: 14px
                line-height: 22px
                color: #2173D3
                cursor: pointer
                border: none
                outline: none
                background: none
                &_is-closed:after
                    content: ''
                    display: inline-block
                    margin: 0 0 3px 6px
                    padding: 2px
                    border: solid #2173D3
                    border-width: 0 1px 1px 0
                    transform: rotate(45deg)

    .social
        &__btns
            display: flex
            padding: 0 30px

        &__btn
            display: flex
            align-items: center

            &:nth-child(n+2)
                margin-left: 25px

            &:hover .social__icon
                opacity: 1

        &__icon
            height: 22px
            width: 25px
            opacity: 0.7
            transition: opacity .1s ease-in-out

            &--like
                background: $like-icon no-repeat

            &--liked
                background: $like-icon-active no-repeat
                opacity: 1

                & ~ .social__count
                    color: #000

            &--comments
                background: $comments-icon no-repeat

        &__count
            margin-left: 8px
            font-size: 1rem
            color: #A4AEBD


</style>
